<template>
  <v-card :loading="loading">
    <v-card-title primary-title class="justify-space-between font-weight-bold">
      Credit card
      <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pb-2">
      <v-form class="pt-3">
        <p class="mb-3">
          All changed information below will be applied to your other shops.
        </p>
        <label class="font-weight-bold">Card number </label>
        <v-text-field
          v-model="form.fields.cardHolder.cardNumber"
          placeholder="1234 1234 1234 1234"
          autocomplete="off"
          :rules="form.rules.cardNumber"
          v-mask="'#### #### #### ####'"
        ></v-text-field>
        <v-row>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">Expires </label>
            <v-text-field
              v-model="form.fields.cardHolder.expires"
              v-mask="'##/##'"
              :rules="form.rules.expires"
              placeholder="MM/YY"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">CVC </label>
            <v-text-field
              v-model="form.fields.cardHolder.cvc"
              placeholder="CVC"
              type="number"
              autocomplete="off"
              :rules="form.rules.cvc"
              v-mask="'####'"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-divider></v-divider>
        <h3 class="my-4">Billing address</h3>
        <p class="mb-3">
          The text must only contain characters from "a-z", "A-Z", "0-9", space (" "), comma(","), dash("-"), slash("/")
          and period (".")
        </p>
        <v-row>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">First name</label>
            <v-text-field
              v-model="form.fields.billingAddress.firstName"
              placeholder="First name"
              autocomplete="off"
              :rules="form.rules.firstName"
            ></v-text-field
          ></v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">Last name </label>
            <v-text-field
              v-model="form.fields.billingAddress.lastName"
              placeholder="Last name"
              autocomplete="off"
              :rules="form.rules.lastName"
            ></v-text-field
          ></v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">Address</label>
            <v-text-field
              v-model="form.fields.billingAddress.address"
              placeholder="Enter address"
              autocomplete="off"
              :rules="form.rules.address"
            ></v-text-field
          ></v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">Apartment, suite, etc... </label>
            <v-text-field
              v-model="form.fields.billingAddress.apartment"
              placeholder="Enter apartment, suite, etc... (optional)"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">City</label>
            <v-text-field
              v-model="form.fields.billingAddress.city"
              placeholder="Enter city"
              autocomplete="off"
              :rules="form.rules.city"
            ></v-text-field
          ></v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">Country</label>
            <v-select
              v-model="form.fields.billingAddress.country"
              :items="countries"
              placeholder="Select country"
              no-data-text="No country"
              item-value="name"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="6" class="py-1">
            <label class="font-weight-bold">ZIP/Postal Code </label>
            <v-text-field
              v-model="form.fields.billingAddress.zipCode"
              placeholder="Enter postal code"
              autocomplete="off"
              :rules="form.rules.zipCode"
              v-mask="'#########'"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <div class="d-flex justify-end align-center py-5 pr-6">
      <v-btn text :height="40" class="mr-3" @click="$emit('close')">Cancel</v-btn>
      <v-btn :loading="loadingBtn" color="primary" depressed @click="submit">Save</v-btn>
    </div>
  </v-card>
</template>

<script>
import Countries from '@/mixins/country';

export default {
  mixins: [Countries],
  data() {
    return {
      form: {
        fields: {
          cardHolder: {
            cardNumber: null,
            expires: null,
            cvc: null,
          },
          billingAddress: {
            firstName: null,
            lastName: null,
            address: null,
            apartment: null,
            city: null,
            country: 'Vietnam',
            zipCode: null,
          },
          discountCode: '',
        },
        rules: {
          cardNumber: [v => !!v || 'Card number is required.'],
          expires: [v => !!v || 'Expiration date is required.'],
          cvc: [v => !!v || 'Your security code is required.', v => /^[0-9]{3,4}$/.test(v) || 'Invalid CVV.'],
          firstName: [v => !!v || 'First name is required.'],
          lastName: [v => !!v || 'Last name is required.'],
          address: [v => !!v || 'Address is required.'],
          city: [v => !!v || 'City is required.'],
          zipCode: [v => !!v || 'Postal code is required.'],
        },
      },
      loading: false,
      loadingBtn: false,
    };
  },
  methods: {
    async getPaymentInfo() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch('getPaymentInfo');
        this.form.fields.cardHolder = data.cardHolder;
        this.form.fields.billingAddress = data.billingAddress;
      } catch (error) {
        console.log('🚀 ~ file: index.vue:276 ~ getPaymentInfo ~ error:', error);
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      try {
        this.loadingBtn = true;
        const result = await this.$store.dispatch('updatePaymentInfo', this.form.fields);
        result && this.$emit('close');
      } catch (error) {
      } finally {
        this.loadingBtn = false;
      }
    },
  },
  created() {
    this.getPaymentInfo();
  },
};
</script>

<style scoped></style>
