import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
const billingService = {
  list(params) {
    const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
    storeRequest.setStoreId(storeId);
    return storeRequest.get('/stores/platform-payments/billing', { params });
  },
};
export default billingService;
