<template>
  <div class="billing__page">
    <Redirect title="Settings" route="website.settings" />
    <h2 class="mt-3 mb-4" style="font-size: 32px; ">Billing</h2>

    <v-row class="mb-6">
      <v-col cols="12" md="3" class="py-0">
        <h3 class="mb-2">Billing information</h3>
        <p>
          This credit card will be used for LatteHub, LatteHub subscription fee and transaction fee.
        </p>
      </v-col>
      <v-col cols="12" md="9" class="py-0">
        <div class="elevation-form pa-4">
          <h3 class="mb-2">Credit card</h3>
          <v-btn outlined @click="showDialog = true">Update credit card</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" class="py-0">
        <h3 class="mb-2">Bills</h3>
        <p>
          Your next bill will be sent to
          <a :href="'mailto:' + userEmail" class="blue--text font-weight-medium">{{ userEmail }}</a>
        </p>
      </v-col>
      <v-col cols="12" md="9" class="py-0">
        <div class="elevation-form pa-4">
          <h3 class="mb-2">All bills</h3>
          <v-data-table
            :headers="table.columns"
            :items="table.records"
            :items-per-page="table.pagination.limit"
            :page.sync="table.pagination.page"
            item-key="_id"
            no-data-text="There are no bills yet"
            hide-default-footer
          >
            <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
              {{ formatDate(createdAt) }}
            </template>
            <template v-slot:[`item.amount`]="{ item: { amount } }">
              {{ formatMoney(amount) }}
            </template>
            <template v-slot:[`item.status`]="{ item: { status } }">
              <v-icon class="success--text" v-if="status === 'success'">mdi-check-circle</v-icon>
              <v-icon class="error--text" v-else>mdi-close-circle</v-icon>
            </template>
          </v-data-table>
          <div class="d-flex justify-end mt-2">
            <pagination
              :length="table.pagination.total"
              v-model="table.pagination.page"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :total-visible="5"
            ></pagination>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" scrollable :width="640" transition="dialog-transition">
      <PaymentInfoDialog v-if="showDialog" @close="showDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import PaymentInfoDialog from './components/PaymentInfoDialog';
import moment from 'moment';
import billingService from '@/apis/billing';
export default {
  components: {
    Redirect,
    PaymentInfoDialog,
  },
  data() {
    return {
      table: {
        loading: false,
        columns: [
          {
            text: 'Type',
            value: 'type',
            sortable: false,
          },
          {
            text: 'Name',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Amount',
            value: 'amount',
            sortable: false,
          },
          {
            text: 'Created',
            value: 'createdAt',
            sortable: false,
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            align: 'center',
          },
        ],
        records: [],
        pagination: {
          limit: 10,
          total: 1,
          page: 1,
        },
      },
      showDialog: false,
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.user?.email;
    },
  },
  methods: {
    async getRecords() {
      try {
        const query = {
          page: this.table.pagination.page,
          limit: this.table.pagination.limit,
        };
        const { data } = await billingService.list(query);
        this.table.pagination = data.pagination;
        this.table.records = data.data;
      } catch (error) {
        console.log('🚀 ~ file: index.vue:137 ~ getRecords ~ error:', error);
      }
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    formatMoney(money) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    },
  },
  created() {
    this.getRecords();
  },
  watch: {
    'table.pagination.page': function() {
      this.getRecords();
    },
  },
};
</script>

<style scoped>
>>> .v-pagination {
  justify-content: flex-end;
}
>>> thead tr th span {
  font-size: 14px;
}
>>> .v-btn--outlined {
  border: solid 1px lightgray;
}
</style>
